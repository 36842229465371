import React from "react";
import {
  Modal,
  SafeAreaView,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

export interface BottomSheetProps {
  bottomSheetStyle?: StyleProp<ViewStyle>;
  bottomSheetTitleStyle?: StyleProp<TextStyle>;
  bottomSheetTitle?: string;
  bottomSheetIconColor?: string;
  bottomSheetVisible: boolean;
  onRequestClose?: () => void;
  onBackdropPress?: boolean;
  children?: React.ReactNode;
}

const BottomSheet = ({
  bottomSheetStyle,
  bottomSheetTitleStyle,
  bottomSheetTitle,
  bottomSheetVisible,
  bottomSheetIconColor,
  onRequestClose,
  onBackdropPress,
  children,
}: BottomSheetProps) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={bottomSheetVisible}
      onRequestClose={onRequestClose}
    >
      <View style={styles.centeredView}>
        {onBackdropPress && (
          <TouchableOpacity
            onPress={onRequestClose}
            style={[StyleSheet.absoluteFill]}
          />
        )}

        <SafeAreaView
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.2)",
            flexDirection: "column-reverse",
          }}
          pointerEvents="box-none"
        >
          <View style={[styles.modalView, bottomSheetStyle]}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 5,
              }}
            >
              <Text
                style={[
                  styles.defaultBottomSheetTitleStyle,
                  bottomSheetTitleStyle,
                ]}
              >
                {bottomSheetTitle}
              </Text>
              <MaterialCommunityIcons
                name="close"
                size={18}
                color={
                  bottomSheetIconColor
                    ? bottomSheetIconColor
                    : "#000"
                }
                style={{ paddingLeft: 20, paddingRight: 5 }}
                onPress={onRequestClose}
              />
            </View>
            <View
              style={{
                width: "100%",
                backgroundColor: "#E0E0E0",
                height: 1,
                marginVertical: 5,
                borderRadius: 20,
              }}
            />
            <View>{children}</View>
          </View>
        </SafeAreaView>
      </View>
    </Modal>
  );
};

export default BottomSheet;

const styles = StyleSheet.create({
  defaultBottomSheetTitleStyle: {
    fontWeight: "bold",
    color: "#000",
  },
  centeredView: {
    flex: 1,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    justifyContent: "flex-end",
  },
  modalView: {
    backgroundColor: "white",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
